import env from "@/env";
import store from "@/store";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  GoogleAuthProvider,
  signInWithPopup,
  GithubAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import router from "@/router";
import { ref } from "vue";
import { app } from "./config";
import { get as getUser, update as updateUser } from "./models/user";

const auth = getAuth(app);

const loadingAuth = ref(false);

const loginViaGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
  } catch (error: any) {
    throw new Error(error);
  }
};

const loginViaGithub = async () => {
  try {
    const provider = new GithubAuthProvider();
    const result = await signInWithPopup(auth, provider);
  } catch (error: any) {
    throw new Error(error);
  }
};

const loginViaEmail = async (email: string) => {
  try {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${env.appUrl}/`,
      // This must be true.
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
};

const confirmSignInLink = async () => {
  try {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      store.commit("user/loading", true);
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.

      await signInWithEmailLink(auth, email as string, window.location.href);

      // Clear email from storage.
      window.localStorage.removeItem("emailForSignIn");
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
      store.commit("user/loading", false);
    }
  } catch (error: any) {
    store.commit("user/loading", false);
    throw new Error(error);
  }
};

const observeAuthState = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const { uid } = user;

      // Fetch user from db
      const userDB = await getUser(uid);

      // If user is not in db, create it
      const newUser = {
        uid,
        name: user.displayName || "",
        email: user.email || "",
        onboarding: {
          stepsCompleted: [],
          pluginId: "",
        },
      };
      if (!userDB) {
        await updateUser(newUser);
        // sync new user to customer.io
        const userToken = await auth.currentUser?.getIdToken();
        await fetch(`${env.serverUrl}/pp/v1/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            email: newUser.email,
          }),
        });
      }

      (window as any).$crisp.push(["set", "user:email", [user.email]]);
      (window as any).posthog.identify(uid, {
        name: user.displayName || "",
        email: user.email,
      });

      if (user.displayName) {
        (window as any).$crisp.push(["set", "user:nickname", [user.displayName]]);
      }

      // Update user in store
      store.commit("user/set", userDB || newUser);

      // Redirect to dashboard if not already there
      if (router.currentRoute.value.name === "loginView") {
        router.push({ name: "gettingStartedView" });
      }
    } else if (router.currentRoute.value.name !== "loginView") {
      // Redirect to login if not already there
      router.push({ name: "loginView" });
    }
  });
};

const logout = async () => {
  await signOut(auth);
};

export default {
  auth,
  loginViaEmail,
  confirmSignInLink,
  loginViaGoogle,
  loginViaGithub,
  observeAuthState,
  logout,
  loadingAuth,
};
