/* eslint-disable object-curly-newline */
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "./config";

const storage = getStorage();

const uploadFile = async (file: File, path: string) => {
  try {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);

    const downloadUrl = getDownloadURL(snapshot.ref);

    return downloadUrl;
  } catch (error: any) {
    console.log(`File upload failed. Errro: ${error}`);
    throw new Error(error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { uploadFile };
