// Import the functions you need from the SDKs you need
// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from "firebase/app";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2vjLDR48m23leEniXbO21Ob0IjqSHD_4",
  authDomain: "security-web-app-e4217.firebaseapp.com",
  projectId: "security-web-app-e4217",
  storageBucket: "security-web-app-e4217.appspot.com",
  messagingSenderId: "933608984036",
  appId: "1:933608984036:web:90c3dda7fb738f10cc0bf1",
  measurementId: "G-0F0QQ6EFPZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
