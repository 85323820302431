/* eslint-disable object-curly-newline */
import User from "@/interfaces/user";
import store from "@/store";
import { doc, setDoc, getDocs, collection, query, where } from "firebase/firestore";
import db from "../db";

const update = async (user: User): Promise<User> => {
  try {
    // Update user on firebase
    await setDoc(doc(db, "users", user.uid), user, { merge: true });
    store.commit("user/update", user);
    return user;
  } catch (error) {
    console.error("Error updating user", error);
    throw new Error(`Error updating user: ${error}`);
  }
};

const get = async (uid: string): Promise<User> => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    const user = (querySnapshot.docs[0]?.data() as User) || undefined;
    return user;
  } catch (error) {
    console.error("Error getting user", error);
    throw new Error(`Error getting user: ${error}`);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { update, get };
