import { Monitoring } from "@/interfaces/monitoring";
import { doc, getDoc, collection } from "firebase/firestore";
import db from "../db";

const load = async (id: string): Promise<Monitoring> => {
  try {
    // Get monitoring from firebase
    const monitoringRef = collection(db, "monitoring");
    const snapshot = await getDoc(doc(monitoringRef, id));
    return snapshot.data() as Monitoring;
  } catch (error) {
    console.error("Error getting monitoring", error);
    throw new Error(`Error getting monitoring: ${error}`);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { load };
