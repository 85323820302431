import { Folder, Request, ObjectProperty, Plugin } from "@/interfaces/plugin";
import { createStore } from "vuex";
import vuexState from "./state";

export default createStore({
  state: vuexState,
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    "user/set": (state, payload) => {
      state.user = payload;
    },
    "user/update": (state, payload) => {
      state.user = payload;
    },
    "plugins/set": (state, payload) => {
      state.plugins = payload;
    },
    "plugin/update": (state, payload: Plugin) => {
      const index = state.plugins.findIndex((p) => payload.id === p.id);
      state.plugins[index] = payload;
    },
    "plugin/add": (state, payload) => {
      state.plugins.push(payload);
    },
    "plugin/delete": (state, payload: string) => {
      const index = state.plugins.findIndex((p) => p.id === payload);
      state.plugins.splice(index, 1);
    },
    "plugin/api/request/add": (
      state,
      payload: {
        pluginId: string;
        request: Request;
      }
    ) => {
      const index = state.plugins.findIndex((p) => p.id === payload.pluginId);
      state.plugins[index].api.requests.push(payload.request);
    },
    "plugin/api/request/update": (
      state,
      payload: {
        pluginId: string;
        request: Request;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const requestIndex = state.plugins[pluginIndex].api.requests.findIndex(
        (r) => r.id === payload.request.id
      );
      state.plugins[pluginIndex].api.requests[requestIndex] = payload.request;
    },
    "plugin/api/request/delete": (
      state,
      payload: {
        pluginId: string;
        requestId: string;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const requestIndex = state.plugins[pluginIndex].api.requests.findIndex(
        (r) => r.id === payload.requestId
      );
      state.plugins[pluginIndex].api.requests.splice(requestIndex, 1);
    },
    "plugin/api/schema/add": (
      state,
      payload: {
        pluginId: string;
        schema: ObjectProperty;
      }
    ) => {
      const index = state.plugins.findIndex((p) => p.id === payload.pluginId);
      state.plugins[index].api.schemas.push(payload.schema);
    },
    "plugin/api/schema/update": (
      state,
      payload: {
        pluginId: string;
        schema: ObjectProperty;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const schemaIndex = state.plugins[pluginIndex].api.schemas.findIndex(
        (s) => s.id === payload.schema.id
      );
      state.plugins[pluginIndex].api.schemas[schemaIndex] = payload.schema;
    },
    "plugin/api/schema/delete": (
      state,
      payload: {
        pluginId: string;
        schemaId: string;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const schemaIndex = state.plugins[pluginIndex].api.schemas.findIndex(
        (s) => s.id === payload.schemaId
      );
      state.plugins[pluginIndex].api.schemas.splice(schemaIndex, 1);
    },
    "plugin/api/folder/add": (
      state,
      payload: {
        pluginId: string;
        folder: Folder;
      }
    ) => {
      const index = state.plugins.findIndex((p) => p.id === payload.pluginId);
      state.plugins[index].api.folders.push(payload.folder);
    },
    "plugin/api/folder/update": (
      state,
      payload: {
        pluginId: string;
        folder: Folder;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const folderIndex = state.plugins[pluginIndex].api.folders.findIndex(
        (f) => f.id === payload.folder.id
      );
      state.plugins[pluginIndex].api.folders[folderIndex] = payload.folder;
    },
    "plugin/api/folder/delete": (
      state,
      payload: {
        pluginId: string;
        folderId: string;
      }
    ) => {
      const pluginIndex = state.plugins.findIndex((p) => p.id === payload.pluginId);
      const folderIndex = state.plugins[pluginIndex].api.folders.findIndex(
        (f) => f.id === payload.folderId
      );
      state.plugins[pluginIndex].api.folders.splice(folderIndex, 1);
    },
    "user/loading": (state, payload) => {
      state.userLoading = payload;
    },
    "request/add": (state, payload) => {
      if (state.requests.find((r) => r.id === payload.id)) return;
      state.requests.push(payload);
    },
    "requests/reset": (state) => {
      state.requests = [];
    },
  },
  actions: {},
  modules: {},
});
