/* eslint-disable object-curly-newline */
import { Plugin } from "@/interfaces/plugin";
import store from "@/store";
import { doc, setDoc, getDocs, collection, query, where, deleteDoc } from "firebase/firestore";
import db from "../db";
// eslint-disable-next-line import/no-cycle
import pluginHelpers from "../../helpers/plugin";

const create = async (plugin: Plugin): Promise<Plugin> => {
  try {
    // Create plugin on firebase
    await setDoc(doc(db, "plugins", plugin.id), plugin);
    store.commit("plugin/add", plugin);
    return plugin;
  } catch (error) {
    console.error("Error creating plugin", error);
    throw new Error(`Error creating plugin: ${error}`);
  }
};

const update = async (plugin: Plugin): Promise<Plugin> => {
  try {
    // Create subdomain
    if (!plugin.subdomain && plugin.nameForModel) {
      const subdomain = await pluginHelpers.createSubdomain(plugin);
      // eslint-disable-next-line no-param-reassign
      plugin.subdomain = subdomain;
    }
    // Update plugin on firebase
    await setDoc(doc(db, "plugins", plugin.id), plugin);
    store.commit("plugin/update", plugin);
    return plugin;
  } catch (error) {
    console.error("Error updating plugin", error);
    throw new Error(`Error updating plugin: ${error}`);
  }
};

const getAll = async (): Promise<Plugin[]> => {
  try {
    // Get all plugins from firebase
    const pluginsRef = collection(db, "plugins");
    const q = query(pluginsRef, where("owner", "==", store.state.user.uid));
    const querySnapshot = await getDocs(q);
    const plugins: Plugin[] = [];
    querySnapshot.forEach((d) => {
      plugins.push(d.data() as Plugin);
    });
    store.commit("plugins/set", plugins);
    return plugins;
  } catch (error) {
    console.error("Error getting plugins", error);
    throw new Error(`Error getting plugins: ${error}`);
  }
};

const deletePlugin = async (pluginId: string): Promise<void> => {
  try {
    // Delete plugin from firebase
    await deleteDoc(doc(db, "plugins", pluginId));

    // Delete plugin from store
    store.commit("plugin/delete", pluginId);
  } catch (error) {
    console.error("Error deleting plugin", error);
    throw new Error(`Error deleting plugin: ${error}`);
  }
};

export { create, update, getAll, deletePlugin };
