import env from "@/env";
import Authentication from "@/firebase/auth";
import { Plugin } from "@/interfaces/plugin";

const sendLog = async (
  plugin: Plugin,
  action: "savedSettings" | "savedAPI" | "createdNew" | "moreThan5000Requests"
) => {
  const userToken = await Authentication.auth.currentUser?.getIdToken();
  const response = await fetch(`${env.serverUrl}/pp/v1/log`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify({
      plugin,
      action,
    }),
  });
};

// eslint-disable-next-line import/prefer-default-export
export { sendLog };
