// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin, Property } from "@/interfaces/plugin";
// eslint-disable-next-line import/no-cycle
import { create as createPlugin } from "@/firebase/models/plugin";
import { v4 as uuidv4 } from "uuid";
import store from "@/store";
import Authentication from "@/firebase/auth";
import env from "@/env";
import { update as updateUser } from "@/firebase/models/user";
import { sendLog } from "./log";

const createSubdomain = async (plugin: Plugin): Promise<string> => {
  // Call api.${domainName}/v1/create-subdomain with firebase token
  const userToken = await Authentication.auth.currentUser?.getIdToken();
  const response = await fetch(`${env.serverUrl}/pp/v1/create-subdomain`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify({
      nameForModel: plugin.nameForModel,
      pluginId: plugin.id,
    }),
  });

  const data = await response.json();

  // TODO: Check if subdomain is already taken
  return data.subdomain;
};

const create = async (): Promise<Plugin> => {
  // Create id for plugin
  const id = uuidv4();
  const plugin: Plugin = {
    id,
    nameForHuman: "",
    nameForModel: "",
    descriptionForHuman: "",
    descriptionForModel: "",
    authentication: {
      active: false,
      contentType: "application/json",
    },
    iconUrl: "",
    contactEmail: "",
    legalInfoUrl: "",
    api: {
      folders: [],
      requests: [],
      schemas: [],
    },
    owner: store.state.user.uid as string,
    subdomain: "",
  };

  // Create plugin on firebase
  await createPlugin(plugin);

  // Send log
  sendLog(plugin, "createdNew");

  return plugin;
};

/**
 * Validate property format
 * @param property
 * @returns
 */
const validateProperty = (
  property: Property,
  ignoreName?: boolean
): { result: boolean; errorMessages?: string[] } => {
  let result = true;
  let errorMessages: string[] = [];

  if (property.type === "schema" && property.schemaId) {
    return { result, errorMessages };
  }

  if (!property.name && !ignoreName && property.type !== "object") {
    result = false;
    errorMessages.push("Property name is required.");
  }
  if (!property.type) {
    result = false;
    errorMessages.push("Property type is required.");
  }
  if (property.values && property.type === "number") {
    const values = property.values.split(",");
    const allNumbers = values.every((value) => !Number.isNaN(Number(value)));
    if (!allNumbers) {
      result = false;
      errorMessages.push("Allowed values must all be numbers.");
    }
  }

  // Validate children if type is object or array
  if (property.type === "array") {
    if (!property.children || property.children.length === 0) {
      result = false;
      errorMessages.push("Property of type array must have at least one property.");
    }
    if (property.children && property.children[0]) {
      const validatedChild = validateProperty(property.children[0], true);
      if (!validatedChild.result && validatedChild.errorMessages) {
        result = false;
        errorMessages = errorMessages.concat(validatedChild.errorMessages);
      }
    }
  } else if (property.type === "object") {
    property.children?.forEach((child) => {
      const validatedChild = validateProperty(child);
      if (!validatedChild.result && validatedChild.errorMessages) {
        result = false;
        errorMessages = errorMessages.concat(validatedChild.errorMessages);
      }
    });
  }

  return { result, errorMessages };
};

/**
 * Validate plugin settings
 */
const validateSettings = (plugin: Plugin): { result: boolean; errorMessages?: string[] } => {
  let result = true;
  const errorMessages: string[] = [];

  // Validate all strings
  if (!plugin.nameForHuman) {
    result = false;
    errorMessages.push("Name for human is required");
  }
  if (!plugin.nameForModel) {
    result = false;
    errorMessages.push("Name for model is required");
  }
  const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/; // has to start with a letter and can only contain alphanumeric characters and underscores
  if (!regex.test(plugin.nameForModel)) {
    result = false;
    errorMessages.push(
      "Name for model must start with a letter and can only contain letters, numbers, and underscores"
    );
  }
  if (!plugin.descriptionForHuman) {
    result = false;
    errorMessages.push("Description for human is required");
  }
  if (!plugin.descriptionForModel) {
    result = false;
    errorMessages.push("Description for model is required");
  }

  // Validate Authentication
  if (plugin.authentication.active) {
    if (!plugin.authentication.schema) {
      result = false;
      errorMessages.push("Authentication schema is required");
    }

    if (plugin.authentication.schema === "bearer") {
      // BEARER
      if (!plugin.authentication.accessLevel) {
        result = false;
        errorMessages.push("Authentication access level is required");
      }
      if (plugin.authentication.accessLevel === "service_http") {
        // Verification token needs to be added AFTER the plugin is submitted
      }
    } else if (plugin.authentication.schema === "oauth") {
      // OAUTH;
      if (!plugin.authentication.clientUrl) {
        result = false;
        errorMessages.push("Authentication client URL is required");
      }
      if (!plugin.authentication.authorizationUrl) {
        result = false;
        errorMessages.push("Authorization URL is required");
      }
      if (plugin.authentication.contentType !== "application/json") {
        result = false;
        errorMessages.push("Authorization content type must be application/json");
      }
      if (!plugin.authentication.verificationToken) {
        // Verification token needs to be added AFTER the plugin is submitted
      }
    }
  }
  return { result, errorMessages };
};

/**
 * Validate plugin api settings
 */
const validateApi = (plugin: Plugin): { result: boolean; errorMessages?: string[] } => {
  let result = true;
  const errorMessages: string[] = [];

  if (plugin.api.requests.length === 0) {
    result = false;
    errorMessages.push("Add at least one request to your LLM plugin.");
  }

  // Validate all folders
  plugin.api.folders.forEach((folder) => {
    if (!folder.name) {
      result = false;
      errorMessages.push("A folder name is required for each folder.");
    }
  });

  // Validate all requests
  plugin.api.requests.forEach((request) => {
    if (!request.name) {
      result = false;
      errorMessages.push("A request name is required for each request.");
    }
    if (!request.operationId) {
      result = false;
      errorMessages.push(`Request operationId is required for request ${request.name}.`);
    }
    const operationIdRegex = /^[a-zA-Z\s_-]+$/; // can only contain letters, spaces, underscores, and hyphens
    if (typeof request.operationId === "string" && !operationIdRegex.test(request.operationId)) {
      result = false;
      errorMessages.push(
        `Request operationId should only contain letters, spaces, underscores, or hyphens for request ${request.name}.`
      );
    }
    if (!request.summary) {
      result = false;
      errorMessages.push(`Request summary is required for request ${request.name}.`);
    }
    if (!request.type) {
      result = false;
      errorMessages.push(`Request type is required for request ${request.name}.`);
    }
    if (!request.url) {
      result = false;
      errorMessages.push(`Request url is required for request ${request.name}.`);
    }
    // Validate url
    if (request.url) {
      try {
        // eslint-disable-next-line no-new
        new URL(request.url);
      } catch (e) {
        result = false;
        errorMessages.push(`Request url is invalid for request ${request.name}.`);
      }
    }
    // Validate headers
    request.headers?.forEach((header) => {
      // const validatedProperty = validateProperty(header);
      // if (!validatedProperty.result) {
      //   result = false;
      //   errorMessages.push(`Header '${header.name}' in request '${request.name}' is invalid:`);
      //   errorMessages.push(...validatedProperty.errorMessages!);
      // }
      if (!header.name) {
        result = false;
        errorMessages.push(`Header name is required for request ${request.name}.`);
      }
      if (!header.default) {
        result = false;
        errorMessages.push(
          `Header value is required for value ${header.name} in request ${request.name}.`
        );
      }
    });
    // Validate params
    request.params?.forEach((param) => {
      const validatedProperty = validateProperty(param);
      if (!validatedProperty.result) {
        result = false;
        errorMessages.push(`Param '${param.name}' in request '${request.name}' is invalid:`);
        errorMessages.push(...validatedProperty.errorMessages!);
      }
    });
    // Validate body
    if (request.body.type === "custom") {
      if (!request.body.property) {
        result = false;
        errorMessages.push(`Add a property for the body in request ${request.name}.`);
      }
      if (request.body.property) {
        const validatedProperty = validateProperty(request.body.property, true);
        if (!validatedProperty.result) {
          result = false;
          errorMessages.push(`Body in request '${request.name}' is invalid:`);
          errorMessages.push(...validatedProperty.errorMessages!);
        }
      }
    }

    if (request.responses.length === 0) {
      result = false;
      errorMessages.push(`Add at least one response for request '${request.name}'.`);
    }

    request.responses?.forEach((response) => {
      if (!response.status || Number.isNaN(response.status)) {
        result = false;
        errorMessages.push(
          `Response status code is required for request '${request.name}'. Status code must be a number.`
        );
      }
      if (!response.description) {
        result = false;
        errorMessages.push(
          `Response description is required for response '${response.status}' of request '${request.name}'.`
        );
      }
      if (response.configuration.type === "custom" && !response.configuration.property) {
        result = false;
        errorMessages.push(
          `Add a property for the response '${response.status}' of request '${request.name}'.`
        );
      }
      if (response.configuration.type === "custom" && response.configuration.property) {
        const validatedProperty = validateProperty(response.configuration.property);
        if (!validatedProperty.result) {
          result = false;
          errorMessages.push(
            `Response '${response.status}' of request '${request.name}' is invalid:`
          );
          errorMessages.push(...validatedProperty.errorMessages!);
        }
      }
    });
  });

  // Validate all schemas
  plugin.api.schemas.forEach((schema) => {
    if (!schema.name) {
      result = false;
      errorMessages.push("Each schema must have a name.");
    }
    if (!schema.children || schema.children.length === 0) {
      result = false;
      errorMessages.push(`Schema '${schema.name}' must have at least one property.`);
    }
    schema.children?.forEach((property) => {
      const validatedProperty = validateProperty(property);
      if (!validatedProperty.result) {
        result = false;
        errorMessages.push(`Property '${property.name}' in schema '${schema.name}' is invalid:`);
        errorMessages.push(...validatedProperty.errorMessages!);
      }
    });
  });

  return { result, errorMessages };
};

/**
 * Track progress of onboarding
 * @param step
 */
const updateOnboardingStep = async (step: string) => {
  if (!store.state.user.onboarding.stepsCompleted.includes("2")) {
    let { stepsCompleted } = store.state.user.onboarding;
    if (Array.isArray(stepsCompleted)) {
      stepsCompleted.push(step);
    } else {
      stepsCompleted = [step];
    }
    store.commit("user/update", {
      ...store.state.user,
      onboarding: {
        stepsCompleted,
        pluginId: store.state.user.onboarding.pluginId,
      },
    });
    await updateUser(store.state.user);
  }
};

export default { create, validateSettings, validateApi, createSubdomain, updateOnboardingStep };
