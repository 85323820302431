/* eslint-disable object-curly-newline */
import store from "@/store";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  getCountFromServer,
} from "firebase/firestore";
import { Request } from "@/interfaces/request";
import { sendLog } from "@/helpers/log";
import { Plugin } from "@/interfaces/plugin";
import db from "../db";

const getAllListener = async (timeframeId: string, pluginId: string): Promise<any> => {
  try {
    let loadNewerThan = new Date();
    if (timeframeId === "1-hour") {
      loadNewerThan = new Date(new Date().setHours(new Date().getHours() - 1));
    } else if (timeframeId === "4-hours") {
      loadNewerThan = new Date(new Date().setHours(new Date().getHours() - 4));
    } else if (timeframeId === "12-hours") {
      loadNewerThan = new Date(new Date().setHours(new Date().getHours() - 12));
    } else if (timeframeId === "24-hours") {
      loadNewerThan = new Date(new Date().setHours(new Date().getHours() - 24));
    } else if (timeframeId === "1-day") {
      loadNewerThan = new Date(new Date().setDate(new Date().getDay() - 1));
    } else if (timeframeId === "7-days") {
      loadNewerThan = new Date(new Date().setDate(new Date().getDay() - 7));
    } else if (timeframeId === "14-days") {
      loadNewerThan = new Date(new Date().setDate(new Date().getDay() - 14));
    }

    // Test if there are more than 5000 requests
    const testQ = query(
      collection(db, "requests"),
      where("pluginId", "==", pluginId),
      where("start", ">=", loadNewerThan.valueOf())
    );
    const snapshot = await getCountFromServer(testQ);
    const testResult = snapshot.data().count;
    if (testResult > 5000) {
      sendLog(
        {
          id: pluginId,
        } as Plugin,
        "moreThan5000Requests"
      );
    }

    // Add the listener
    const q = query(
      collection(db, "requests"),
      where("pluginId", "==", pluginId),
      where("start", ">=", loadNewerThan.valueOf())
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((d) => {
        // Remove "prompt" from params and add it to the request object
        const requestData = d.data() as Request;
        if (requestData.params && "prompt" in requestData.params) {
          requestData.prompt = requestData.params.prompt;
          delete requestData.params.prompt;
        }
        // Remove 'prompt' query parameter from requestData.href
        const url = new URL(requestData.href);
        if (url.searchParams.has("prompt")) {
          url.searchParams.delete("prompt");
          requestData.href = url.toString();
        }
        store.commit("request/add", requestData);
      });
    });
    return unsubscribe;
  } catch (error) {
    console.error("Error getting requests", error);
    throw new Error(`Error getting requests: ${error}`);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getAllListener };
