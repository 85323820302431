import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/LoginView.vue";
import GettingStarted from "../views/GettingStartedView.vue";
import SettingsView from "../views/SettingsView.vue";
import apiView from "../views/APIView.vue";
import DeploymentView from "../views/DeploymentView.vue";
import DebuggingView from "../views/DebuggingView.vue";
import MonitoringView from "../views/MonitoringView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "loginView",
    component: Login,
  },
  {
    path: "/getting-started",
    name: "gettingStartedView",
    component: GettingStarted,
  },
  {
    path: "/:pluginId/settings",
    name: "settingsView",
    component: SettingsView,
  },
  {
    path: "/:pluginId/api",
    name: "apiView",
    component: apiView,
  },
  {
    path: "/:pluginId/deployment",
    name: "deploymentView",
    component: DeploymentView,
  },
  {
    path: "/:pluginId/debugging",
    name: "debuggingView",
    component: DebuggingView,
  },
  {
    path: "/:pluginId/monitoring",
    name: "monitoringView",
    component: MonitoringView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
