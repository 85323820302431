import State from "@/interfaces/state";

const state: State = {
  userLoading: false,
  user: {
    name: "",
    email: "",
    uid: "",
    onboarding: {
      stepsCompleted: [],
      pluginId: "",
    },
  },
  plugins: [],
  requests: [],
};

export default state;
