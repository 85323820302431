/* eslint-disable no-restricted-syntax */
import { ObjectProperty, Property, Request, StringProperty } from "@/interfaces/plugin";
import store from "@/store";
import yaml from "js-yaml";
import { v4 as uuidv4 } from "uuid";

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const parseInput = (input: string): { [key: string]: any } => {
  try {
    // Parse input
    if (isJson(input)) return JSON.parse(input);
    if (!isJson(input)) return yaml.load(input) as any;
    return {};
  } catch (error) {
    console.error("Error parsing input", error);
    return {};
  }
};

const parseProperty = (property: any, schemas: ObjectProperty[], propertyName?: any): Property => {
  // Parse property
  if (property.type === "string" || property.schema?.type === "string") {
    const stringProperty: StringProperty = {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "string",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: Array.isArray(property.required)
        ? property.required?.includes(property.name) || false
        : property.required,
    };
    return stringProperty;
  }
  if (property.type === "object" || property.schema?.type === "object") {
    const objectProperty: ObjectProperty = {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "object",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: false,
      children: [],
    };
    if (property.properties) {
      for (const child of Object.keys(property.properties)) {
        objectProperty.children?.push(parseProperty(property.properties[child], schemas, child));
      }
    }
    return objectProperty;
  }
  if (property.type === "array" || property.schema?.type === "array") {
    const objectProperty: ObjectProperty = {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "array",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: false,
      children: [],
    };
    if (property.items) {
      objectProperty.children?.push(parseProperty(property.items, schemas));
    }
    return objectProperty;
  }
  if (property.$ref) {
    const schema = schemas.find((s) => s.name === property.$ref.split("/").pop());
    return {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "schema",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: false,
      schemaId: schema?.id || "",
    };
  }
  if (property.type === "boolean" || property.schema?.type === "boolean") {
    const prop: Property = {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "boolean",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: Array.isArray(property.required)
        ? property.required?.includes(property.name) || false
        : property.required,
    };
    return prop;
  }
  if (
    property.type === "number" ||
    property.type === "integer" ||
    property.schema?.type === "number" ||
    property.schema?.type === "integer"
  ) {
    const prop: Property = {
      id: uuidv4(),
      name:
        propertyName ||
        property.title ||
        property.name ||
        property.description?.replace(/[^A-Z0-9]+/gi, "_") ||
        "",
      type: "number",
      ...(property.description && property.description.trim() !== ""
        ? { description: property.description }
        : {}),
      required: Array.isArray(property.required)
        ? property.required?.includes(property.name) || false
        : property.required,
    };
    return prop;
  }
  const prop: Property = {
    id: uuidv4(),
    name:
      propertyName ||
      property.title ||
      property.name ||
      property.description.replace(/[^A-Z0-9]+/gi, "_") ||
      "",
    type: property.type || property.schema?.type || "",
    ...(property.description && property.description.trim() !== ""
      ? { description: property.description }
      : {}),
    required: false,
  };
  return prop;
};

const evalImport = (
  input: string,
  pluginId: string
): {
  requests: Request[];
  schemas: ObjectProperty[];
  warnings: string[];
  duplicates: string[];
  error?: string;
} => {
  try {
    const plugin = store.state.plugins.find((p) => p.id === pluginId);
    if (!plugin) throw new Error("Plugin not found.");
    const value = parseInput(input);
    const requests: Request[] = [];
    const schemas: ObjectProperty[] = [];
    const warnings: string[] = [];
    const duplicates: string[] = [];

    if (value.servers?.length === 0 || !value.servers || !value.servers[0]?.url)
      throw new Error(
        "No server found in OpenAPI specification. You need to add a server url for the import to work."
      );
    const server = value.servers[0].url;

    // Loop threw schemas in openapi
    if (value.components?.schemas) {
      for (const schema of Object.keys(value.components.schemas)) {
        const properties: ObjectProperty["children"] = [];
        if (value.components.schemas[schema].properties) {
          for (const property of Object.keys(value.components.schemas[schema].properties)) {
            properties.push(
              parseProperty(
                value.components.schemas[schema].properties[property],
                schemas,
                property
              )
            );
          }
        }
        const object: ObjectProperty = {
          id: uuidv4(),
          name: schema,
          type: "object",
          required: false,
          children: properties,
        };
        schemas.push(object);
      }
    }

    // Loop threw requests in openapi
    for (const path of Object.keys(value.paths)) {
      const endpoint = path;

      for (const method of Object.keys(value.paths[endpoint])) {
        // eslint-disable-next-line no-continue
        if (method === "servers") continue;

        // Parse parameters
        const parameters: StringProperty[] = [];
        if (value.paths[endpoint][method].parameters) {
          for (const parameter of value.paths[endpoint][method].parameters) {
            const param: StringProperty = parseProperty(parameter, schemas) as StringProperty;
            if (parameter.schema.type === "array" && parameter.schema?.items?.type)
              param.children = [
                {
                  id: uuidv4(),
                  name: "",
                  type: parameter.schema.items.type,
                  required: parameter.required,
                  description: "",
                },
              ];
            param.position = parameter.in;
            parameters.push(param);
          }
        }

        // Parse body if present
        let body: Request["body"];
        if (
          value.paths[endpoint][method].requestBody &&
          value.paths[endpoint][method].requestBody.content &&
          value.paths[endpoint][method].requestBody.content["application/json"] &&
          value.paths[endpoint][method].requestBody.content["application/json"].schema &&
          Object.keys(value.paths[endpoint][method].requestBody.content["application/json"].schema)
            .length
        ) {
          body = {
            type: "custom",
            property: parseProperty(
              value.paths[endpoint][method].requestBody.content["application/json"].schema,
              schemas
            ) as ObjectProperty,
          };
          if (body.property) {
            body.property.required = value.paths[endpoint][method].requestBody.required;
          }
        } else {
          body = {
            type: "none",
          };
        }

        // Parse responses
        const responses: Request["responses"] = [];
        if (value.paths[endpoint][method]?.responses) {
          for (const response of Object.keys(value.paths[endpoint][method]?.responses)) {
            let configuration: Request["responses"][0]["configuration"];

            // Parse response body if present
            if (
              value.paths[endpoint][method].responses[response].content &&
              value.paths[endpoint][method].responses[response].content["application/json"] &&
              value.paths[endpoint][method].responses[response].content["application/json"]
                .schema &&
              Object.keys(
                value.paths[endpoint][method].responses[response].content["application/json"].schema
              ).length
            ) {
              configuration = {
                type: "custom",
                property: parseProperty(
                  value.paths[endpoint][method].responses[response].content["application/json"]
                    .schema,
                  schemas
                ) as ObjectProperty,
              };
            } else {
              configuration = {
                type: "none",
              };
            }

            const res: Request["responses"][0] = {
              id: uuidv4(),
              status: Number(response),
              description: value.paths[endpoint][method].responses[response].description,
              configuration,
            };
            responses.push(res);
          }
        }

        // Construct request
        const request: Request = {
          id: uuidv4(),
          folderId: "",
          name: value.paths[endpoint][method].operationId,
          operationId: value.paths[endpoint][method].operationId,
          summary: value.paths[endpoint][method].description
            ? value.paths[endpoint][method].description
            : value.paths[endpoint][method].summary, // "description" and "summary" are both valid options and have different meanings accroding to Swagger. If you provide both to OpenAI, they only take the description that's why we prioritize it here.
          type: method.toUpperCase() as Request["type"],
          url: `${server}${endpoint}`,
          params: parameters,
          headers: [],
          body,
          responses,
          auth: false,
        };
        requests.push(request);
      }
    }

    // Check for duplicates in requests based on url and method
    for (const request of requests) {
      const duplicate = plugin.api.requests.find(
        (r) => r.url === request.url && r.type === request.type
      );
      if (duplicate) duplicates.push(request.id);
    }

    // Check for duplicates in schemas based on name and first level properties
    for (const schema of schemas) {
      const duplicate = plugin.api.schemas.find(
        (s) => s.name === schema.name && s.children?.length === schema.children?.length
      );
      if (duplicate) duplicates.push(schema.id);
    }

    return { requests, schemas, warnings: [], duplicates };
  } catch (e: any) {
    console.log(e);
    return { requests: [], schemas: [], warnings: [e.message], duplicates: [], error: e.message };
  }
};

export default evalImport;
